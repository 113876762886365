import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBellOn} from '@fortawesome/pro-duotone-svg-icons';
import {faBell} from '@fortawesome/pro-solid-svg-icons';
import styles from './NotificationButton.module.scss';

const NOTIFICATION_BELL_COUNT_LIMIT = 99;

export type NotificationState = 'none' | 'new' | 'showing';

export interface NotificationButtonProps {
  onClick: () => void;
  notificationState?: NotificationState;
  notificationCount: number;
}

const NotificationButton: React.FC<NotificationButtonProps> = ({
  onClick,
  notificationState = 'none',
  notificationCount = 0,
}) => {
  const isNotificationCountAboveLimit =
    notificationCount > NOTIFICATION_BELL_COUNT_LIMIT;

  return (
    <button
      data-testid="notificationButton"
      onClick={onClick}
      className={`
      ${styles.button}
      ${styles[notificationState]}
    `}
    >
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon
          icon={notificationState === 'none' ? faBell : faBellOn}
          className={`
          ${styles.icon}
          ${styles[notificationState]}
        `}
        />
        {notificationState === 'new' && (
          <div className={styles.circleBackground}>
            <span
              className={`${styles.circle} ${
                isNotificationCountAboveLimit ? styles.limit : ''
              }`}
            >
              {isNotificationCountAboveLimit ? '99+' : notificationCount}
            </span>
          </div>
        )}
      </span>
    </button>
  );
};

export default NotificationButton;
