/* eslint-disable react-hooks/exhaustive-deps */
import {Button} from 'components';
import React, {useEffect, useRef, useState} from 'react';
import styles from './TablePagination.module.scss';

interface RequestParams {
  limit: number;
  offset: number;
}

export interface TablePaginationProps {
  totalItems?: number;
  itemsPerPage?: number;
  onPageChange?(requestParams: RequestParams): void;
}

function TablePagination({
  totalItems = 0,
  onPageChange,
}: TablePaginationProps): JSX.Element {
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const totalOfPages = Math.ceil(totalItems / itemsPerPage);

  const lastPage = useRef(0);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current && currentPage) {
      const offset = currentPage * itemsPerPage;

      onPageChange?.({limit: itemsPerPage, offset});
      lastPage.current = currentPage;
    } else {
      isFirstRender.current = false;
    }
  }, [currentPage]);

  function handlePageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {value} = event.target;
    const isValidPage = Number(value) > 0 && Number(value) <= totalOfPages;

    setCurrentPage(isValidPage ? Number(value) : undefined);
  }

  function handleNextPageClick() {
    setCurrentPage((currentPage || lastPage.current) + 1);
  }

  function handlePreviousPageClick() {
    setCurrentPage((currentPage || lastPage.current) - 1);
  }

  function handleItemsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    const {value} = event.target;

    if (value) {
      setItemsPerPage(Number(value));
      onPageChange?.({limit: Number(value), offset: 0});
    }
  }

  return (
    <tfoot>
      <tr>
        <td colSpan={100} className={styles.tableFooter}>
          <div className={styles.tablePagination}>
            <Button
              variant="tertiary"
              text="Anterior"
              backgroundColor="transparent"
              disabled={currentPage === 1}
              leftIcon={{icon: 'chevron-left'}}
              onClick={() => handlePreviousPageClick()}
            />
            <input
              type="number"
              min={1}
              step={1}
              max={totalItems}
              value={currentPage}
              onChange={handlePageChange}
              data-testid="pagination-input"
            />
            de {totalOfPages}
            <Button
              variant="tertiary"
              text="Próxima"
              backgroundColor="transparent"
              rightIcon={{icon: 'chevron-right'}}
              onClick={() => handleNextPageClick()}
              disabled={currentPage === totalOfPages}
            />
          </div>

          <div className={styles.tableSize}>
            <p>mostrando</p>
            <input
              type="number"
              min={1}
              step={1}
              max={totalItems}
              defaultValue={itemsPerPage}
              onChange={handleItemsPerPage}
              data-testid="items-per-page"
            />
            <p>de {totalItems}</p>
          </div>
        </td>
      </tr>
    </tfoot>
  );
}

export default TablePagination;
